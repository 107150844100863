<template>
  <div class="dashboard">
    <dashboard-info-block />

    <div class="row">
      <div class="flex xs12">
        <DashboardTable />
      </div>
    </div>
  </div>
</template>

<script>
import DashboardInfoBlock from './DashboardInfoBlock'
import DashboardTable from './DashboardTable'

export default {
  name: 'dashboard',
  components: {
    DashboardInfoBlock,
    DashboardTable,
  },
  mounted: function () {
    this.$store.commit('setLoading', false)
  },
  methods: {
    addAddressToMap ({ city, country }) {
      this.$refs.dashboardMap.addAddress({ city: city.text, country })
    },
  },
}
</script>

<style lang="scss">
.row-equal .flex {
  .va-card {
    height: 100%;
  }
}

.dashboard {
  .va-card {
    margin-bottom: 0 !important;
  }
}
</style>
