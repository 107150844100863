<template>
  <va-card :title="$t('dashboard.table.title')">
    <div class="row align--center mb-1">
      <div class="flex xs12 sm6">
        <va-input
          class="ma-0"
          :value="term"
          :placeholder="$t('tables.searchByName')"
          @input="search"
          removable
        >
          <va-icon name="fa fa-search" slot="prepend" />
        </va-input>
      </div>

      <div class="flex xs12 md3 offset--md3">
        <va-select
          :options="perPageOptions"
          v-model="perPage"
          label="Itens por página"
        />
      </div>

    </div>

    <va-data-table
      :per-page="perPage"

      :fields="mode ? detailedFields : fields"
      :data="filteredData"
      :loading="loading"
      hoverable
    >
      <template slot="icon">
        <va-icon name="fa fa-user" color="secondary" />
      </template>
      <template v-slot:starred="props">
        <va-icon
          v-if="props.rowData.starred"
          name="fa fa-star"
          color="warning"
        />
      </template>
      <template v-slot:status="props">
        <va-badge :color="getStatusColor(props.rowData.status)">
          {{ props.rowData.status }}
        </va-badge>
      </template>

      <template v-slot:actions="props">
        <va-button
          small
          outline
          color="success"
          icon="fa fa-check"
          class="ma-0"
          @click="resolveUser(props.rowData)"
        >
          {{ $t('dashboard.table.resolve') }}
        </va-button>
      </template>
    </va-data-table>
  </va-card>
</template>

<script>
import debounce from 'lodash/debounce'
import operacoesService from '../../services/Cobranca/OperacoesService'

export default {
  data () {
    return {
      operacoes: [],
      perPage: 15,
      perPageOptions: ['15', '25', '35', '50', '75', '100', '200', '300', '400', '500'],
      loading: false,
      term: null,
      mode: 0,
    }
  },
  computed: {
    fields () {
      return [{
        name: 'razaoSocial',
        title: 'Empresa',
      }, {
        name: 'parceiro',
        title: 'Parceiro',
      }, {
        name: 'quantidadeParcelas',
        title: 'Prazo',
      },
      {
        name: 'parcelasPagas',
        title: 'Pagas',
      },
      {
        name: 'parcelasVencidas',
        title: 'Vencidas',
      },
      {
        name: 'statusAcordo',
        title: 'Status Renegociação',
      }]
    },
    modeOptions () {
      return [{
        value: 0,
        label: this.$t('dashboard.table.brief'),
      }, /*, {
        value: 1,
        label: this.$t('dashboard.table.detailed'),
      } */]
    },
    filteredData () {
      if (!this.term || this.term.length < 1) {
        return this.operacoes
      }

      return this.operacoes.filter(item => {
        return item.razaoSocial.toLowerCase().startsWith(this.term.toLowerCase())
      })
    },
  },
  methods: {
    getStatusColor (status) {
      if (status === 'paid') {
        return 'success'
      }

      if (status === 'processing') {
        return 'info'
      }

      return 'danger'
    },
    search: debounce(function (term) {
      this.term = term
    }, 400),
  },
  async mounted () {
    this.operacoes = (await operacoesService.statusOperacoes()).data.data
  },
}
</script>

<style lang="scss">
</style>
